// import React from 'react'

import 'react-medium-image-zoom/dist/styles.css'
import 'leaflet/dist/leaflet.css'

import './src/styles/global.css'

// export function wrapPageElement({ element, props }) {
//   return <Layout  {...props}>{element}</Layout>
// }
