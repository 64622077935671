// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-education-contentful-education-slug-js": () => import("./../../../src/pages/education/{contentfulEducation.slug}.js" /* webpackChunkName: "component---src-pages-education-contentful-education-slug-js" */),
  "component---src-pages-education-index-js": () => import("./../../../src/pages/education/index.js" /* webpackChunkName: "component---src-pages-education-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-projects-contentful-project-slug-js": () => import("./../../../src/pages/projects/{contentfulProject.slug}.js" /* webpackChunkName: "component---src-pages-projects-contentful-project-slug-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

